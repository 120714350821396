import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 98;
  top: 0;
  bottom: 0;
  right: -100%;
  background: #fff;
  transition: 0.5s ease-in-out;

  max-width: 370px;
  width: 100%;
  overflow: hidden;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.16);
  &.open {
    right: 0;
  }
  border-bottom: 1px solid #002a6a;
  padding: 4rem 5.5rem;
`

export const MenuLinks = styled.nav`
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.38;
      text-align: left;
      color: #002a6a;
      cursor: pointer;
      a {
        text-decoration: none;
        color: #002a6a;
      }

      & + li {
        margin-top: 2rem;
      }
      :active {
        opacity: 0.5;
      }
    }
    ${media.lessThan("600px")`
      li{
       font-size: 1.4rem;
       & + li {
        margin-top: 0.5rem;
      }
      } 
    `}
  }
`
