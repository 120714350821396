import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  position: absolute;
  top: 55px;
  right: 38px;
  cursor: pointer;
  border-radius: 100%;
  border: 2px solid #fff;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  &.open {
    border-color: #002a6a;
  }
  transition: 0.5s ease-in-out;
  ${media.lessThan("600px")`
      top: 90px;
    `}
`

export const AnimateIcon = styled.span`
  position: relative;
  display: block;
  background: #fff;
  width: 30px;
  height: 2px;
  transition: 0.5s ease-in-out;

  ::after,
  ::before {
    background: #fff;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s ease-in-out;
  }
  ::before {
    top: 10px;
  }
  ::after {
    top: -10px;
  }

  &.open {
    transform: rotate(45deg);
    background: #002a6a;

    ::before {
      transform: rotate(90deg);
      top: 0;
    }

    ::after {
      transform: rotate(90deg);
      top: 0;
    }
  }

  &.open {
    ::before,
    ::after {
      background: #002a6a;
    }
  }
`
