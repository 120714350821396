import styled from 'styled-components';
import media from 'styled-media-query';
export const Container = styled.div`
  display: flex;
  align-items: top;
  min-height: 450px;
  width: 100%;
  padding: 0rem 5rem 5rem;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #00b1b1 1%, #002a6a 99%);
  flex-wrap: wrap;

  ${media.lessThan('600px')`
    padding: 2rem 2rem;
    flex-direction: column;
    align-items: center;
  `}
`;
export const HubFintech = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 1rem;
  margin-right: 5rem;
  flex: 1;
  img {
    width: 100px;
    margin-bottom: 24px;
  }
  & div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 20px;
  }

  & h3,
  p {
    margin-left: 1.2rem;
    font-size: 1.5rem;
    line-height: 2;
    letter-spacing: 0.65px;
    text-align: left;
    color: #ffffff;
    white-space: nowrap;
    display: flex;
    align-items: center;

    hr {
      height: 10px;
      width: 2px;
      border-radius: 100px;
      margin: 0 15px;
      background-color: #fff;
    }
    strong {
      font-weight: bold;
      letter-spacing: normal;
      cursor: pointer;
      user-select: none;
      &:active {
        opacity: 0.5;
      }
    }
  }
  ${media.lessThan('600px')`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0rem;
    
    img {
      margin-bottom: 12px;
    }
    & div{
      margin-left: 0px;
      align-items: center;
    }
  `}
`;

export const MapSiteLinks = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  flex: 1;
  justify-content: top;
  margin-right: 0;
  margin-top: 1rem;

  p {
    font-size: 1.3rem;
    line-height: 1.5;
    letter-spacing: 0.65px;
    text-align: left;
    color: #ffffff;

    b {
      font-weight: bold;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  div {
    display: flex;
    flex-direction: row !important;

    div {
      display: flex;
      flex-direction: column !important;
      height: 100%;

      & > li {
        display: flex;
        flex-direction: column;

        p {
          font-size: 1.5rem;
          line-height: 1.5;
          letter-spacing: 0.65px;
          text-align: left;
          color: #ffffff;

          b {
            font-weight: bold;
            line-height: 3;
            text-transform: uppercase;
          }

          a {
            text-decoration: none;
            color: #ffffff;

            &:hover {
              text-decoration: underline;
            }
          }
        }
        & + li {
          margin-top: 8px;
        }
      }
    }
  }
  ${media.lessThan('600px')`
    align-items: center;
    order: 2;
    margin-right:0px;
    max-width: 60%!important;

    div {
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      text-align: center;
      div {
        & > li {
          p {
            text-align:center
          }
        }
      }
    }
  `}

    .link {
      &:hover {
        text-decoration: underline;
        color: white;
        cursor: pointer;
      }
    }
`;

export const Carreira = styled.ul`
display: flex;
flex-direction: column;
list-style: none;
flex: 1;
justify-content: top;
margin-right: 0;
margin-top:1rem;

p {
  font-size: 1.3rem;
  line-height: 1.5;
  letter-spacing: 0.65px;
  text-align: left;
  color: #ffffff;

  b {
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
  }
}

  div {
    li {
      display: flex;
      flex-direction: column;

      img {
        width: 36px!important;
      }

      p {
        font-size: 1.5rem!important;
        line-height: 1.5;
        letter-spacing: 0.65px;
        text-align: left;
        color: #ffffff;

        b {
          font-weight: bold;
          line-height: 3;
          text-transform: uppercase;
        }

        a {
          color: #ffffff!important;

          &:hover {
            text-decoration: underline!important;
            color: white!important;
            cursor: pointer!important;
          }
        }
      }
    }
  }  

${media.lessThan('600px')`
  align-items: center;
  order: 2;
  margin-right:0px;
  max-width: 60%!important;

  div {
    li {
      p{
        text-align: center;
      }
    }
  }
`}

  .link {
    &:hover {
      text-decoration: underline;
      color: white;
      cursor: pointer;
    }
  }
`;

export const Atendimento = styled.ul`
display: flex;
flex-direction: column;
list-style: none;
flex: 1;
justify-content: top;
min-width: 400px;
margin-top:1rem;

  a {
    font-size: 1.5rem;
    color: #ffffff !important;
  }

  b.title {
    text-transform: uppercase;
  }
  
  .atendimento-email {
    max-width: 400px;
    overflow-wrap: anywhere;
    li:last-child p b {
      text-transform: lowercase !important;
    }
    li:first-child {
      max-width: 115px;
    }
  }

  p {
    font-size: 1.3rem;
    line-height: 1.5;
    letter-spacing: 0.65px;
    text-align: left;
    color: #ffffff;

    b {
      font-weight: bold;
      line-height: 1;
    }
  }

  div{
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 300px;
      li {
        display: flex;
        text-align: match-parent;
        p{
          font-size: 1.5rem !important;
          line-height: 1.5;
          letter-spacing: 0.65px;
          color: #ffffff;
          b {
            font-weight: bold;
            line-height: 1.5;
            text-transform: uppercase;
            ${media.lessThan('600px')`
              font-size: 1.2rem!important;
            `}
          }
        }
      }
    }
  }
  ${media.lessThan('600px')`
    align-items: center;
    order: 1;
    margin: 20px auto;
    max-width: 60%;
    min-width: 0;
    p {
      text-align: left;
      min-width: 89px!important;
    }
  `}
`;

export const Hours = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  margin-top: 1rem;
  max-width: 300px;

  li {
    list-style-type: none;
  }

  li p {
    font-size: 1.5rem;
    line-height: 1.5;
    letter-spacing: 0.65px;
    text-align: left;
    color: #ffffff;
  }
`;
