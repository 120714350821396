import { Link } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { LanguageContext } from "../../context/LanguageContext"
import ButtonMenu from "../ButtonMenu"
import { Container, MenuLinks } from "./styles"

export default function Menu({ scrollToForm }) {
  const {idioma} = useContext(LanguageContext)

  const [showMenuHamburguer, setShowMenuHamburguer] = useState(false)

  const handleMenuHamburguer = () => {
    setShowMenuHamburguer(!showMenuHamburguer)
  }
  const handleClassOpenMenu = () => (showMenuHamburguer ? "open" : "")

  useEffect(() => {
    if (window) window.onscroll = () => setShowMenuHamburguer(false)
  }, [])

  const openDocs = () => window.open("http://docs.hubfintech.com.br/#/Glossary")
  const openCareers = () => window.open("https://jobs.kenoby.com/hubfintech")
  const openPix = () => window.open("https://produtos.hubfintech.com.br/pix")

  var weAre = ["Somos Hub Fintech", "We are Hub Fintech", "Somos Hub Fintech"]
  var pix = ["Nossa oferta Pix", "Our Pix offer", "Nuestra oferta de Pix"]
  var integrations = ["Integrações", "Integrations", "Integraciones"]
  var contact = ["Fale com a Hub Fintech", "Talk to Hub Fintech", "Contáctese con Hub Fintech"]
  var career = ["Carreira", "Career", "Carrera"]

  return (
    <>
      <Container className={handleClassOpenMenu()}>
        <MenuLinks>
          <ul>
            <li>
              <p>
                <Link to="/somos-hub">{weAre[idioma]}</Link>
              </p>
            </li>
            <li>
              <p onClick={openPix}>{pix[idioma]}</p>
            </li>
            <li>
              <p onClick={openDocs}>{integrations[idioma]}</p>
            </li>
            <li onClick={scrollToForm}>
              <p>{contact[idioma]}</p>
            </li>
            <li>
              <p onClick={openCareers}>{career[idioma]}</p>
            </li>
          </ul>
        </MenuLinks>
      </Container>
      <ButtonMenu
        open={handleClassOpenMenu()}
        handleMenuHamburguer={handleMenuHamburguer}
      />
    </>
  )
}
